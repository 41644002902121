import React from "react"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

// Load AboutUs component lazily
const AboutUs = loadable(() => import("../components/AboutUs"), {
  fallback: <div className="loading-fallback">Loading About Us...</div>,
})

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/about-us/" />
      </Helmet>
      <AboutUs />
    </Layout>
  )
}
